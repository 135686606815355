import { Item } from 'common-front/src/designSystem/components/dropdown/item';
import { Title } from 'common-front/src/designSystem/components/dropdown/title';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationsPaths } from 'common/src/delegationsPaths';
import { DelegationsSpaceDelegationQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { groupBy, partition, sortBy } from 'lodash-es';
import * as React from 'react';

interface IDelegationMenuProps {
    delegations: DelegationsSpaceDelegationQuery['organization']['delegations']['nodes'];
}

export const DelegationMenu = (props: IDelegationMenuProps) => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();

    if (props.delegations.length > 1) {
        const [eventsDelegations, communityDelegations] = partition(
            props.delegations,
            (d) => typeof d.event?.id === 'number'
        );
        const grouped = sortBy(
            Object.values(groupBy(eventsDelegations, (d) => d.event!.id)),
            ([delegation]) => delegation.event!.name.toLowerCase()
        );

        return (
            <>
                {isNonEmptyArray(communityDelegations) && (
                    <Title>{translate('communaut_20896')}</Title>
                )}

                {communityDelegations.map((delegation) => (
                    <Item
                        key={delegation.id}
                        onClick={() => {
                            history.push(
                                DelegationsPaths.DELEGATION({
                                    organizationId,
                                    delegationId: delegation.id
                                })
                            );
                        }}
                    >
                        {delegation.name}
                    </Item>
                ))}

                {grouped.map((delegations, index) => {
                    const firstDelegation = delegations[0];
                    const sorted = sortBy(delegations, (d) => d.name.toLowerCase());

                    return (
                        <React.Fragment key={index}>
                            <Title>{firstDelegation.event!.name}</Title>

                            {sorted.map((delegation) => (
                                <Item
                                    key={delegation.id}
                                    onClick={() => {
                                        history.push(
                                            DelegationsPaths.DELEGATION({
                                                organizationId,
                                                eventId: delegation.event?.id,
                                                delegationId: delegation.id
                                            })
                                        );
                                    }}
                                >
                                    {delegation.name}
                                </Item>
                            ))}
                        </React.Fragment>
                    );
                })}

                <Separator direction="horizontal" color="gray100" />

                <Item
                    onClick={() => {
                        history.push(DelegationsPaths.DELEGATIONS_LIST(organizationId));
                    }}
                >
                    {translate('voir_toutes_les_79250')}
                </Item>

                <Separator direction="horizontal" color="gray100" />
            </>
        );
    } else {
        return null;
    }
};
